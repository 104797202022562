export default {
  methods: {
    toClipboard(value) {
      this.$clipboard(value)
      this.$message.info('Zkopírováno do schránky')
    },
    copyEmailsToClipboard(users) {
      if (Array.isArray(users)) {
        let copyBoard = ''
        users.forEach(x => {
          copyBoard += x.email + ';'
        })
        this.$clipboard(copyBoard)
        this.$message.info('Zkopírováno do schránky')
        return true
      }
      this.$clipboard(users)
      this.$message.info('Zkopírováno do schránky')
    },
  },
}
