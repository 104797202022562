<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Uživatelé - Zákazníci - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown class="mr-2" :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item-group title="Skupiny">
                <a-menu-item :key="group.grp_id" v-for="group in groups" :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                  <a-popconfirm @confirm="confirmGroup(selectedRowKeys, group.grp_id, group.grp_name)" okText="Potvrdit" cancelText="Zrušit">
                    <template slot="title">
                      <p>Opravdu si přejete změnit tyto položky?</p>
                      <div v-if="selectedRowKeys.length > 0">
                        {{ selectedRowKeys }}
                      </div>
                    </template>
                    {{ group.grp_name }}
                  </a-popconfirm>
                </a-menu-item>
              </a-menu-item-group>
              <a-menu-divider />
              <a-menu-item key="__copyEmails" :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-icon type="copy"></a-icon>
                Zkopírovat e-mail
                <a-tooltip placement="top">
                  <div slot="title">
                    Zkopíruje e-maily všech vybraných uživatelů do schránky.<br>Vložení pak provedete (Ctrl + V) pro Windows a (Command + V) pro macOS.
                  </div>
                  <a-icon type="question-circle"></a-icon>
                </a-tooltip>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete zabanovat tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                    <a-input-number placeholder="Zabanovat na X dní" :min="1" :defaultValue="1" v-model="daysBanTo"></a-input-number>
                  </template>
                  <a-icon type="stop"></a-icon>
                  Zabanovat
                </a-popconfirm>
              </a-menu-item>
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmRevert(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete zrušit ban pro tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  <a-icon type="check"></a-icon>
                  Zrušit ban
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-button @click="copyEmailsToClipboard(subscribers)">
            <a-icon type="copy"></a-icon>
            Zkopírovat e-maily
          </a-button>&nbsp;
          <a-tooltip placement="top">
            <div slot="title">
              Zkopíruje e-maily všech odběratelů novinek do schránky.<br>Vložení pak provedete (Ctrl + V) pro Windows a (Command + V) pro macOS.
            </div>
            <a-icon type="question-circle"></a-icon>
          </a-tooltip>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        :locale="{ filterConfirm: 'OK' }"
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.uer_id"
        :columns="columns"
        :loading="loading"
        :dataSource="userRegistered"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="uer_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/user/registered/detail/' + value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <template slot="email" slot-scope="text">
          <a-button @click="copyEmailsToClipboard(text + ';')" size="small" type="link">
            <span v-if="searchText">
              <template
                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                >{{fragment}}</mark>
                <template v-else>{{fragment}}</template>
              </template>
            </span>
            <template v-else>{{text}}</template>
          </a-button>
        </template>
        <span
          slot="dates"
          slot-scope="text"
        >
        <span v-if="searchText && text !== null">
            <template
              v-for="(fragment, i) in toLocaleDate(text).toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else-if="text != null">{{toLocaleDate(text)}}</template>
          <template v-else>-</template>
        </span>
        <template slot="groups" slot-scope="record">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in (record.public_group.grp_name + '; ' + record.private_group.grp_name).toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{record.public_group.grp_name + '; ' + record.private_group.grp_name}}</template>
        </template>
        <span
          slot="active"
          slot-scope="value"
          :class="[value === undefined ? '' : value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
        >
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'ano' : 'ne'}}</template>
        </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/user/registered/detail/'+record.uer_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a-popconfirm @confirm="confirmDelete([record.uer_id])" okText="Potvrdit" cancelText="Zrušit" v-if="record.banned_to === null">
            <template slot="title">
              <p>Opravdu si přejete zabanovat položku: #{{ record.uer_id }} ?</p>
              <a-input-number placeholder="Zabanovat na X dní" :min="1" :defaultValue="1" v-model="daysBanTo"></a-input-number>
            </template>
            <a-button icon="stop" size="small">Zabanovat</a-button>
          </a-popconfirm>
          <a-popconfirm @confirm="confirmRevert([record.uer_id])" okText="Potvrdit" cancelText="Zrušit" v-else>
            <template slot="title">
              <p>Opravdu si přejete zrušit ban pro položku: #{{ record.uer_id }} ?</p>
            </template>
            <a-button icon="undo" size="small">Zrušit ban</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ userRegistered.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import copy from '@/services/copy'

export default {
  components: { ActionTools },
  mixins: [copy],
  data: function () {
    return {
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      selectedItems: [],
      daysBanTo: 1,
      routerOptions: [],
      columns: [
        {
          title: 'ID',
          dataIndex: 'uer_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.uer_id - b.uer_id,
          scopedSlots: {
            customRender: 'uer_id',
          },
        },
        {
          title: 'Skupiny',
          sorter: (a, b) => a.public_group.grp_name.localeCompare(b.public_group.grp_name),
          scopedSlots: {
            customRender: 'groups',
          },
        },
        {
          title: 'E-mail',
          dataIndex: 'email',
          sorter: (a, b) => a.email.localeCompare(b.email),
          scopedSlots: {
            customRender: 'email',
          },
        },
        {
          title: 'Zákaz do',
          dataIndex: 'banned_to',
          sorter: (a, b) => new Date(a.banned_to) - new Date(b.banned_to),
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'created_at',
          sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Odběr novinek',
          dataIndex: 'uer_is_newsletter',
          sorter: (a, b) => a.uer_is_newsletter - b.uer_is_newsletter,
          filters: [
            { text: 'Ano', value: 'true' },
            { text: 'Ne', value: 'false' },
          ],
          onFilter: (value, record) => (value === 'true' && record.uer_is_newsletter === true) || (value === 'false' && record.uer_is_newsletter === false),
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Aktivní',
          dataIndex: 'uer_is_active',
          sorter: (a, b) => a.uer_is_active - b.uer_is_active,
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    userRegistered: function () {
      return this.$store.getters['userRegistered/filteredItems'](this.searchText)
    },
    subscribers: function () {
      const allItems = this.$store.getters['userRegistered/allItems']
      return allItems && allItems.data ? allItems.data.filter(x => x.uer_is_newsletter === true) : []
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            if (this.selectedRowKeys.length === 0) {
              this.userRegistered.forEach(item => {
                this.selectedRowKeys.push(item.uer_id)
              })
              this.selectedItems = [].concat(this.userRegistered)
            } else {
              this.selectedRowKeys = []
              this.selectedItems = []
            }
          },
        }],
        onSelection: this.onSelection,
      }
    },
    groups() {
      return this.$store.getters['group/filteredItems']('')
    },
  },
  methods: {
    onSelectChange(selectedRowKeys, items) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedItems = items
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('userRegistered/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(userRegistered) {
      this.loading = true
      this.$store.dispatch('userRegistered/delete', { days: this.daysBanTo, items: userRegistered })
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = userRegistered.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !userRegistered.includes(x)))
            this.selectedItems = this.selectedItems.filter(x => this.selectedRowKeys.includes(x.uer_id))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmGroup(userRegistered, grpId, grpName) {
      this.loading = true
      this.$store.dispatch('userRegistered/patch', { items: userRegistered, group: { grpId: grpId, grpName: grpName } })
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = userRegistered.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !userRegistered.includes(x)))
            this.selectedItems = this.selectedItems.filter(x => this.selectedRowKeys.includes(x.uer_id))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmRevert(userRegistered) {
      this.loading = true
      this.$store.dispatch('userRegistered/revert', userRegistered)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = userRegistered.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !userRegistered.includes(x)))
            this.selectedItems = this.selectedItems.filter(x => this.selectedRowKeys.includes(x.uer_id))
          }
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
    initData() {
      this.loading = true
      this.$store.dispatch('group/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleMenuClick(item) {
      if (item.key === '__copyEmails') {
        this.copyEmailsToClipboard(this.selectedItems)
      }
    },
  },
  created() {
    this.initData()
    if (this.userRegistered.length === 0) {
      this.handleRefresh()
    }
  },
}
</script>

<style lang="scss" module scoped>

</style>
